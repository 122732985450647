<template>
  <v-card width="420">
    <v-container class="pa-4">
      <v-row no-gutters>
        <v-col cols="12">
          <p class="flights-list-title my-2">
            {{ $t('flights.select_a_flight') }}
          </p>
        </v-col>
        <v-col cols="12" class="flights-list" @scroll.self="onScroll">
          <FlightListItem
            v-for="(flight, index) in sortedContextFlights"
            :key="index"
            :flight="flight"
            :divider="false"
            @submitFlight="selectFlight"
          />
        </v-col>

        <v-col cols="12">
          <v-btn
            class="add-button mt-5 py-6"
            depressed
            text
            block
            color="primary"
            @click="setAddFlight"
          >
            <v-icon>mdi-plus</v-icon>
            {{ $t('flights.add_a_flight') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import FlightListItem from '@/modules/flights/features/FlightsList/FlightListItem';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import moment from 'moment';
import HotJarMixins from '@/core/mixins/HotJarMixins';

export default {
  name: 'FlightsList',
  components: { FlightListItem },
  mixins: [FlightsMixins, GoogleTagManagerMixins, HotJarMixins],

  data() {
    return {
      scrollTop: 0,
      selectedFlight: null,
    };
  },

  computed: {
    showTopShadow() {
      return this.scrollTop > 10;
    },

    flightAnalytics() {
      if (this.selectedFlight) {
        return {
          flight_number: this.selectedFlight.flightNumber,
          departure_date: moment(this.selectedFlight.departureTime).format(
            'MMM DD, YYYY'
          ),
        };
      } else return null;
    },
  },

  methods: {
    isActiveFlight(flight) {
      return flight.id === this.selectedContextFlight?.id;
    },

    selectFlight(flight) {
      this.selectedFlight = flight;
      this.pushSelectFlight(this.flightAnalytics);
      if (this.$route.query && this.$route.query.addFlightModal) {
        let query = Object.assign({}, this.$route.query);
        delete query['addFlightModal'];
        this.$router.replace({ query }).catch((err) => {});
      }
      this.setSelectedFlight(flight);
    },

    setAddFlight() {
      this.setShowAddFlight(true);
      this.pushHotJarAnalytics('add_flight');
    },

    onScroll(e) {
      this.scrollTop = e.target.scrollTop;
    },
  },
};
</script>

<style scoped lang="scss">
.flights-list {
  @media (min-width: map-get($grid-breakpoints, md)) {
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &-title {
    color: var(--v-grey8-base) !important;
    transition: 0.3s box-shadow linear;
    @include font-size(16, 150, 600);
  }
}

.add-button {
  border: 1px dashed var(--v-grey5-base);
  @include font-size(16, 150, 500);
}
</style>
