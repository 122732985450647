<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <template v-if="invalidFlightAlert">
          <ToastComponent @toastClosed="clearInvalidFlightAlert" />
        </template>

        <v-img
          alt="logo"
          contain
          :src="logo"
          transition="scale-transition"
          max-height="88"
          position="center"
          class="mt-16"
        />

        <template v-if="heroFlightConfig">
          <v-row no-gutters>
            <v-col cols="12">
              <p class="title-text mt-10 mb-0">
                {{ getHeroTitle }}
              </p>
            </v-col>
          </v-row>
        </template>

        <v-row justify="center" class="mt-10 mx-3 mx-md-0">
          <template
            v-if="
              hasContextFlights &&
                !showAddFlight &&
                showFlightsInContextOnLandingPageFF
            "
          >
            <FlightsList />
          </template>

          <template v-else>
            <AddFlightCta />
          </template>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddFlightCta from '@/modules/flights/features/AddFlight/AddFlightCta';
import ConfigsMixins from '@/core/mixins/ConfigsMixins';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import FlightsList from '@/modules/flights/features/FlightsList/FlightsList';
import FlightsFFMixins from '@/modules/flights/mixins/FlightsFFMixins';
import ToastComponent from '@/core/components/ToastComponent.vue';
import FlightsSearchMixins from '@/modules/flights/mixins/FlightsSearchMixins';

export default {
  name: 'FlightLandingPage',
  mixins: [ConfigsMixins, FlightsMixins, FlightsFFMixins, FlightsSearchMixins],
  components: {
    FlightsList,
    AddFlightCta,
    ToastComponent,
  },

  mounted() {
    this.setShowAddFlight(false);
  },

  watch: {
    selectedContextFlight() {
      this.$router.push({ name: 'concierge' });
    },

    recentlyAddedFlights: {
      immediate: true,
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.clearRecentlyAddedFlights();
          }, 2000);
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.title-text {
  text-align: center;
  padding: 0 20px;
  @include font-size(27, 103.7037, 500);

  @media (min-width: map-get($grid-breakpoints, lg)) {
    padding: 0 22%;
    @include font-size(48, 121.0208, 500);
  }
}
</style>
